@font-face {
	font-family: 'Linearicons-Free';
	src:url('../public/fonts/Linearicons-Free.eot?w118d');
	src:url('../public/fonts/Linearicons-Free.eot?#iefixw118d') format('embedded-opentype'),
		url('../public/fonts/Linearicons-Free.woff2?w118d') format('woff2'),
		url('../public/fonts/Linearicons-Free.woff?w118d') format('woff'),
		url('../public/fonts/Linearicons-Free.ttf?w118d') format('truetype'),
		url('../public/fonts/Linearicons-Free.svg?w118d#Linearicons-Free') format('svg');
	font-weight: normal;
	font-style: normal;
}


body{
  background-color: #333 !important;
}

/* Sidebar styles */
.sidebar-fixed {
  width: 250px; /* Fixed width of the sidebar */
  position: fixed; /* Sidebar stays fixed on the left */
  left: 0;
  top: 0;
  bottom: 0;
  color: #ddd;
  background-color: #333; /* Background of the sidebar */
 
  z-index: 100; /* Ensure the sidebar stays above content */
  height: 100vh; /* Full height of the viewport */
}
.sidebar-item {
  color: #ddd;
}

/* Main content styles */
.main-content {
  background-color: #333 !important;
  /* margin-left: 250px; Push content to the right of the sidebar */
  padding: 20px;
  padding-top: 0;
  height: 100vh; /* Full height to ensure content displays properly */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds viewport height */
  display: flex; /* Use flexbox to align content next to the sidebar */
  flex-grow: 1; /* Main content will expand to take remaining space */
}

/* Hide hamburger menu button on larger screens */
.menu-button {
  display: none;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .menu-button {
    display: block; /* Show hamburger button on mobile */
  }

  .main-content {
    margin-left: 0; /* Remove margin-left for mobile view */
    padding: 0; /* Remove padding for main content on mobile view */
    height: 100vh; /* Full height for mobile view */
    overflow-y: auto; /* Ensure scroll on mobile view */
    display: block; /* Stack main content under the sidebar on mobile */
  }

  .sidebar-fixed {
    width: 100%; /* Full width for sidebar on mobile view */
    height: 100%; /* Full height for sidebar on mobile view */
    position: static; /* Remove fixed positioning for mobile view */
    border-right: none; /* Remove border for mobile view */
  }
}



.section-title {
  margin-top: 1rem !important;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.title{
  color: white;
  font-size: 30px;
  font-weight: bold;
  padding-top: 0px  !important;
}



/* Intro Section */
.intro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 5%;
  margin-top: 3rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Intro Text */
.intro-text {
  flex: 0 0 45%; /* Take up 55% of the width */
  max-width: 45%; /* Ensure it doesn't exceed 55% */
}

.intro-text h1 {
  font-size: 3rem;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

.intro-text p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #f9f9f9;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #ffffff;
  color: #e45447;
  padding: 15px 30px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
}

.cta-button:hover {
  background-color: #ff6d62;
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

/* SlideShow Container */
.slideshow-container {
  flex: 0 0 55%; /* Take up 45% of the width */
  max-width: 53%; /* Ensure it doesn't exceed 45% */
  border-radius: 12px;
}
/* Responsive Design */
@media (max-width: 768px) {
  .intro {
    flex-direction: column;
    text-align: center;
    padding: 30px 5%;
  }

  .intro-text {
    max-width: 100%;
    margin-bottom: 30px;
    flex: 0 0 auto;
  }

  .slideshow-container {
    max-width: 100%;
    flex: 0 0 auto;
  }
}


/* Prevent Horizontal Scrolling */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Services Section */
.services {
  background-color: #333 !important;
  padding: 80px 20px;
  text-align: center;
  color: #fff; /* White text color for contrast */
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
  position: relative;
  z-index: 1;
  width: 100%;
}

/* Title Styling */
.services h2 {
  font-size: 3rem;
  color: #fff;
  margin-bottom: 50px;
  font-weight: bold;
  text-transform: uppercase;
  animation: fadeInUp 1s ease-out;
  text-shadow: 3px 3px 15px rgba(0, 0, 0, 0.3);
}

/* Service List Layout */
.service-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  box-sizing: border-box; /* Ensure that padding doesn’t cause overflow */
}

/* Service Card Styling */
.service {
  background-color: #1a1a1a; /* Dark gray background for service cards */
  border-radius: 20px;
  padding: 35px;
  width: calc(33.333% - 20px); /* Ensure 3 cards per row on larger screens */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  position: relative;
  z-index: 1;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Ensure padding is inside the width */
}

.service:hover {
  transform: translateY(-12px);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
  background-color: #333;
}

.service h3 {
  font-size: 1.8rem;
  color: #e45447;
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.service ul {
  list-style: none;
  padding: 0;
}

.service li {
  font-size: 1.2rem;
  color: #ddd;
  margin-bottom: 10px;
  padding-left: 10px;
  position: relative;
}

.service li::before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
  font-size: 0rem;
}

/* Icon Styling */
.service .service-icon {
  margin-bottom: 20px;
  font-size: 2.5rem;
  color: #e45447;
  transition: transform 0.3s ease;
}

.service:hover .service-icon {
  transform: scale(1.1); /* Slight zoom effect on icon */
}

/* Media Queries for Mobile */
@media screen and (max-width: 768px) {
  .service {
    width: 100%; /* Make each service card take the full width on mobile */
    margin-bottom: 20px; /* Optional: Add spacing between the cards */
  }
}

/* Base styling for the sticky navbar */
.sticky-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: none;
    transition: box-shadow 0.3s ease;
    padding: 10px 0;
    width: 100%;
      background-color: #333; /* Dark background for the navbar */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}



.sticky-navbar.scrolled {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Flexbox styling for navbar layout */
.navbar-container {
    display: flex;
    justify-content: space-between;
    
    width: 100%;
}

@media screen and (max-width: 768px) {
.logo-image1 {
  width: auto;
}
}




/* Styling for the logo */
#logo {
  padding: 5px;
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

/* Styling for nav menu */
.nav-menu {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 2rem;
  flex-wrap: wrap; /* Ensure menu items wrap when needed */
}

/* Navbar links */
.nav-menu li {
  display: inline-block;
  margin-left: 20px;
}

.nav-menu a {
  color: #fff;
  text-decoration: none;
  font-weight: semibold;
  font-size: 18px;

}

/* Active link styling */
.nav-menu .active {
  color: #fff;
}

.nav-menu a:hover {
  color: #e45447;
  cursor: pointer;
}

/* Styling for the hamburger icon */
.hamburger-menu {
  display: none;
  cursor: pointer;
  font-size: 24px;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 20px; /* Position the hamburger to the right */
}

/* Navbar links container */
.nav-menu-container {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
}

/* Media Queries for Mobile */
@media screen and (max-width: 768px) {
  /* Hide the nav menu by default */
  .nav-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #333;
    position: absolute;
    top: 90px; /* Adjust according to the height of your navbar */
    left: 0;
 
    padding: 10px;
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  /* Show the nav menu when it is active */
  .nav-menu.active {
    display: flex;
  }

  .nav-menu :hover {
    color: #e45447;
  }

  /* Show hamburger menu on mobile */
  .hamburger-menu {
    display: block;
  }
.nav-link {
  color: #000; /* Default link color */
  text-decoration: none;
}

.nav-link.highlight {
  color: orange; /* Highlight active link */
  font-weight: bold; /* Optional for emphasis */
  border-bottom: 2px solid orange; /* Optional underline for highlight */
}

  /* Navbar container adjustments for mobile */
  .navbar-container {
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding: 10px;
  }
  @media (max-width: 768px) {
  .gallery-image {
    width: 100%; /* Stack images one after another */
    max-width: 100%; /* Ensure full width on smaller screens */
    margin: 10px 0; /* Adds spacing between stacked images */
  }
}

}





